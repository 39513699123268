import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import MultiPortfolioSelector from 'components/advisor/proposal/portfolio-selector/multi';
import WeightedPortfolioSelector from 'components/advisor/proposal/portfolio-selector/weighted';
import PropTypes from 'prop-types';
import React from 'react';

const ProposalFormGroup = ({
  canRemoveGroup,
  fields,
  handleRecommendedChange,
  handleTargetChange,
  id,
  proposalType,
  recommendedGroups,
  removeGroup,
  scope,
  targetGroups,
  targetSuggestions
}) => {
  const handleRemoveGroup = () => {
    removeGroup(id);
  };

  const targetGroupPortfolios = targetGroups[id] || [];
  const recommendedGroupPortfolios = recommendedGroups[id] || [];

  const portfoliosTotalAmount = targetGroupPortfolios.reduce(
    (acc, portfolio) => acc + portfolio.amount,
    0
  );
  const otherTargetGroupsPortfolioIds = Object.entries(targetGroups)
    .filter(([targetGroupId]) => targetGroupId !== id)
    .reduce((acc, [, portfolios]) => [...acc, ...portfolios.map(portfolio => portfolio.value)], []);
  const groupTargetSuggestions = targetSuggestions.map(category => {
    const options = category.options.filter(
      option => !otherTargetGroupsPortfolioIds.includes(option.value)
    );
    return { ...category, options };
  });

  return (
    <div key={id} className="target-recommended-group">
      {canRemoveGroup && (
        <button
          aria-label="Remove"
          className="remove-target-recommended-group"
          onClick={handleRemoveGroup}
          title="Remove Group"
          type="button"
        >
          <i className="fs-icon-xmark-linear" />
          <span>Remove</span>
        </button>
      )}

      {proposalType !== ACCOUNT_PROPOSAL_TYPE && (
        <MultiPortfolioSelector
          defaultValue={targetGroupPortfolios}
          onChange={handleTargetChange}
          suggestions={groupTargetSuggestions}
        />
      )}

      <div className="recommended">
        <div className="recommended__label">
          <label htmlFor="recommended">Select a matching model *</label>
        </div>
        <WeightedPortfolioSelector
          defaultValues={recommendedGroupPortfolios}
          key={`recommended-weighted-portfolio-${id}`}
          onChange={handleRecommendedChange}
          portfoliosTotalAmount={portfoliosTotalAmount}
          scope={scope}
          withPercentages={!!fields.recommendedWithPercentages.value}
          withTotalRowError={false}
        />
      </div>
    </div>
  );
};

ProposalFormGroup.defaultProps = {
  fields: {}
};

ProposalFormGroup.propTypes = {
  canRemoveGroup: PropTypes.bool.isRequired,
  fields: PropTypes.object,
  handleRecommendedChange: PropTypes.func.isRequired,
  handleTargetChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  proposalType: PropTypes.string.isRequired,
  recommendedGroups: PropTypes.object.isRequired,
  removeGroup: PropTypes.func.isRequired,
  scope: PropTypes.array.isRequired,
  targetGroups: PropTypes.object.isRequired,
  targetSuggestions: PropTypes.array.isRequired
};

export default ProposalFormGroup;
