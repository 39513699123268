import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormGroup, VerboseErrorInput } from '..';

const ManagementFeeField = ({ field, label }) => (
  <FormGroup {...field} className="management-fee form-group">
    <VerboseErrorInput {...field} className="form-control" label={label}>
      <NumericFormat
        allowNegative={false}
        className="form-control management-fee__percentage"
        decimalScale={2}
        onValueChange={field.onChange}
        suffix="%"
        value={field.value}
      />
    </VerboseErrorInput>
  </FormGroup>
);

ManagementFeeField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string
};

ManagementFeeField.defaultProps = {
  label: 'Management fee *'
};

export default ManagementFeeField;
