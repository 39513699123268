import {
  MAX_START_DATE,
  MIN_START_DATE,
  toFloatPrecision
} from 'components/advisor/proposal/header/utils';
import React from 'react';
import { toast } from 'react-toastify';
import { DateValidator, validation } from 'utils/form';

export const HOUSEHOLD_LABEL = 'HOUSEHOLD';
export const TARGET_BREAKDOWN_ENABLED = 'target-breakdown-default';

export const validate = (values, { isIPS }) => {
  const errors = {};
  errors.target =
    errors.target || validation.required(values.target) || validation.empty(values.target);
  errors.template = errors.template || validation.required(values.template);

  errors.startingValue =
    errors.startingValue ||
    validation.required(values.startingValue) ||
    validation.floatPositive(values.startingValue) ||
    validation.nonZero(values.startingValue);

  errors.period =
    errors.period ||
    new DateValidator(values.period?.[0])
      .required()
      .valid()
      .min(MIN_START_DATE)
      .max(MAX_START_DATE)
      .monthDifference(values.period?.[1], 9)?.error;

  if (!isIPS) {
    errors.targetManagementFee =
      errors.targetManagementFee ||
      validation.required(values.targetManagementFee) ||
      validation.isFloatPercentage(values.targetManagementFee);

    errors.recommendedManagementFee =
      errors.recommendedManagementFee ||
      validation.required(values.recommendedManagementFee) ||
      validation.isFloatPercentage(values.recommendedManagementFee);

    errors.benchmarkManagementFee =
      errors.benchmarkManagementFee ||
      validation.required(values.benchmarkManagementFee) ||
      validation.isFloatPercentage(values.benchmarkManagementFee);

    errors.yearlyWithdrawalRate =
      errors.yearlyWithdrawalRate ||
      validation.required(values.yearlyWithdrawalRate) ||
      validation.isFloatPercentage(values.yearlyWithdrawalRate);

    errors.yearlyWithdrawalAmount =
      errors.yearlyWithdrawalAmount || validation.floatPositive(values.yearlyWithdrawalAmount);
  }

  return errors;
};

export const reportNotification = (name, verb, celebration = true) =>
  toast.success(
    <div>
      {celebration && (
        <>
          <span role="img" aria-label="celebration">
            🎉
          </span>{' '}
          Great job!
        </>
      )}{' '}
      <span style={{ fontWeight: 'bold' }}>{name}</span> was {verb} successfully
    </div>
  );

export const hasInitialValues = values => values && Array.isArray(values) && !!values.length;

export const setRecommendedGroupTargets = (portfolios, targets) =>
  portfolios.map(portfolio => ({
    ...portfolio,
    targetContentType: 'account',
    targetObjectIds: targets.map(portfolio => portfolio.value)
  }));

export const processRecommendedGroups = (
  targetGroups,
  recommendedGroups,
  withPercentages = true
) => {
  const mergedPortfolios = {};
  const targetPortfolios = Object.values(targetGroups).reduce(
    (acc, portfolios) => [...acc, ...portfolios],
    []
  );
  const targetPortfoliosTotalValue = targetPortfolios.reduce(
    (acc, portfolio) => acc + portfolio.amount,
    0
  );

  Object.entries(recommendedGroups).forEach(([groupId, portfolios]) => {
    const targetGroup = targetGroups[groupId] || [];
    const targetGroupTotalValue = targetGroup.reduce((acc, portfolio) => acc + portfolio.amount, 0);

    if (!targetGroup.length || !targetGroupTotalValue) return;

    portfolios.forEach(portfolio => {
      const key = `${portfolio.value}-${portfolio.type}`;
      const weight = withPercentages
        ? toFloatPrecision((portfolio.weight * targetGroupTotalValue) / targetPortfoliosTotalValue)
        : toFloatPrecision(portfolio.weight);

      if (!mergedPortfolios[key]) mergedPortfolios[key] = { ...portfolio, weight };
      else {
        const targetObjectIds = new Set([
          ...mergedPortfolios[key].targetObjectIds,
          ...portfolio.targetObjectIds
        ]);
        mergedPortfolios[key].targetObjectIds = Array.from(targetObjectIds);
        mergedPortfolios[key].weight = toFloatPrecision(mergedPortfolios[key].weight + weight);
      }
    });
  });

  const portfolios = Object.values(mergedPortfolios);

  if (withPercentages && !!portfolios.length) {
    const portfoliosTotalWeight = portfolios.reduce((acc, portfolio) => acc + portfolio.weight, 0);
    const adjustment = toFloatPrecision(100 - portfoliosTotalWeight);
    portfolios[portfolios.length - 1].weight = toFloatPrecision(
      portfolios[portfolios.length - 1].weight + adjustment
    );
  }

  return portfolios;
};
