/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

/**
 * Merge the suggestions into a single object.
 * It's normal to have repeated portfolios, in which case their values are added.
 * Then convert the values to 2-decimal weights.
 * If the result does not add to 100%, distribute the difference using the "largest remainder method".
 * @param {array} suggestions the list of model portfolio suggestions
 * @param {number} decimals the number of decimal places
 * @returns the list of recommended model portfolios
 */
export const getRecommendedModelPortfolios = (suggestions, decimals = 2) => {
  const totalAmount = suggestions.reduce((acc, s) => acc + (s.amount ?? 0), 0);
  if (!totalAmount) return [];

  const delta = 10 ** (2 * decimals);
  const normalizedRecommendedSuggestions = suggestions.reduce((acc, s) => {
    const prev = acc[s.value] ?? {};
    const amount = (prev.amount ?? 0) + (s.amount ?? 0);
    const targetObjectIds = new Set([
      ...(prev.targetObjectIds ?? []),
      ...(s.targetObjectIds ?? [])
    ]);
    return {
      ...acc,
      [s.value]: {
        ...s,
        amount,
        targetContentType: 'account',
        targetObjectIds: Array.from(targetObjectIds)
      }
    };
  }, {});

  let recommended = Object.values(normalizedRecommendedSuggestions).map(s => {
    const weight = (s.amount * delta) / totalAmount;
    const weightRnd = Math.floor(weight);
    return { ...s, weight: weightRnd, rest: weight - weightRnd };
  });

  let rest = delta - recommended.reduce((acc, s) => acc + s.weight, 0);
  recommended = _.sortBy(recommended, [s => -s.rest]).map(s => {
    rest -= 1;
    delete s.rest;
    return { ...s, weight: (rest >= 0 ? s.weight + 1 : s.weight) / Math.sqrt(delta) };
  });

  return recommended;
};
