import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { DEFAULT_STARTING_VALUE } from 'components/advisor/proposal/header/utils';
import MultiPortfolioSelector from 'components/advisor/proposal/portfolio-selector/multi';
import WeightedPortfolioSelector from 'components/advisor/proposal/portfolio-selector/weighted';
import { VerboseErrorInput } from 'components/form';
import ManagementFeeField from 'components/form/management-fee-field';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { setPrecision } from 'utils/utils';
import { HOUSEHOLD_LABEL } from '../utils';

const ProposalFormSimpleVersion = ({
  fields,
  isIPS,
  onTargetChange,
  proposalType,
  scope,
  setRecommendedTotalValue,
  targetSuggestions
}) => {
  const [householdOption, setHouseholdOption] = useState(null);

  const handleRecommendedChange = portfolios => {
    if (!portfolios) portfolios = [];
    setRecommendedTotalValue(
      setPrecision(
        portfolios.reduce((acc, portfolio) => acc + portfolio.weight, 0),
        2
      )
    );
    fields.recommended.onChange(portfolios);
  };

  const handleTargetChange = (portfolios, actionMeta) => {
    if (!portfolios) portfolios = [];
    const targetValue = portfolios.reduce((acc, portfolio) => acc + portfolio.amount, 0);

    // evaluates the household to add the associated accounts
    if (actionMeta.option && actionMeta.option.isFullHousehold) {
      const accountOptions = targetSuggestions.filter(element => element.label !== HOUSEHOLD_LABEL);
      const householdOption = targetSuggestions.find(element => element.label === HOUSEHOLD_LABEL);
      if (householdOption) {
        // sets the household option
        setHouseholdOption(householdOption);

        // sets household accounts into the `options` attribute
        const householdPortfolios = actionMeta.option.suggestions;
        const householdOptionWithPortfolios = { ...householdOption, options: householdPortfolios };

        // sets selected targets as the current investor accounts + the household accounts
        const filteredPortfolios = portfolios.filter(element => !element.isFullHousehold);
        const selectedPortfolios = [...filteredPortfolios, ...householdPortfolios];
        fields.target.onChange(selectedPortfolios);
        onTargetChange(selectedPortfolios, [...accountOptions, householdOptionWithPortfolios]);
      }
    } else {
      let currentOptions = null;

      // allows deleting accounts belonging to the household
      if (actionMeta.action === 'remove-value' && actionMeta.removedValue.belongsToHouseholdGroup) {
        const householdPortfolios = portfolios
          ? portfolios.filter(element => element.belongsToHouseholdGroup)
          : [];
        if (!householdPortfolios.length) {
          const accountOptions = targetSuggestions.filter(
            element => element.label !== HOUSEHOLD_LABEL
          );
          currentOptions = [...accountOptions, householdOption];
        }
      }

      fields.target.onChange(portfolios);
      onTargetChange(portfolios, currentOptions);
    }

    // the value of `startingValue` is being normalized (see src/app/reducers/form.js).
    // In consequence, its `initialValue` is dirty (not pristine). We need to check if it
    // has been visited/tocuhed before overriding the value
    if (
      fields.startingValue.pristine ||
      !fields.startingValue.visited ||
      fields.startingValue.autofilled
    )
      fields.startingValue.autofill(targetValue || DEFAULT_STARTING_VALUE);
  };

  const toggleRecommendedUnit = () => {
    fields.recommendedWithPercentages.onChange(!fields.recommendedWithPercentages.value);
  };

  const portfoliosTotalAmount = (fields.target.value || []).reduce(
    (acc, portfolio) => acc + portfolio.amount,
    0
  );

  return (
    <>
      <div className="step">
        <div className="step__container step__container--target" data-ips={isIPS}>
          {proposalType !== ACCOUNT_PROPOSAL_TYPE && (
            <MultiPortfolioSelector
              suggestions={targetSuggestions}
              defaultValue={fields.target.value}
              onChange={handleTargetChange}
            />
          )}

          <div>
            {!isIPS && <ManagementFeeField field={fields.targetManagementFee} />}
            <div className="recommended-label">
              <label htmlFor="target-label">Customize label (optional)</label>
              <VerboseErrorInput
                name="target-label"
                type="text"
                {...fields.targetLabel}
                placeholder="Target"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="step">
        <div className="step__container" data-ips={isIPS}>
          <div className="recommended">
            <div className="recommended__label">
              <label htmlFor="recommended">Select a matching model (optional)</label>
              <Toggle
                className="toggle-unit"
                icons={{
                  checked: <span className="toggle-unit__icon">%</span>,
                  unchecked: <span className="toggle-unit__icon">$</span>
                }}
                id="toggle-recommended-unit"
                onChange={toggleRecommendedUnit}
                checked={!!fields.recommendedWithPercentages.value}
              />
            </div>
            <WeightedPortfolioSelector
              defaultValues={fields.recommended.initialValue}
              key="recommended-weighted-portfolio"
              onChange={handleRecommendedChange}
              portfoliosTotalAmount={portfoliosTotalAmount}
              scope={scope}
              withPercentages={!!fields.recommendedWithPercentages.value}
            />
          </div>

          <div>
            {!isIPS && <ManagementFeeField field={fields.recommendedManagementFee} />}
            <div className="recommended-label">
              <label htmlFor="recommended-label">Customize label (optional)</label>
              <VerboseErrorInput
                name="model-label"
                type="text"
                {...fields.recommendedLabel}
                placeholder="Model"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProposalFormSimpleVersion.defaultProps = {
  fields: {}
};

ProposalFormSimpleVersion.propTypes = {
  fields: PropTypes.object,
  isIPS: PropTypes.bool.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  proposalType: PropTypes.string.isRequired,
  scope: PropTypes.array.isRequired,
  setRecommendedTotalValue: PropTypes.func.isRequired,
  targetSuggestions: PropTypes.array.isRequired
};

export default ProposalFormSimpleVersion;
